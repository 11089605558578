module controllers {
    export module master {

        interface IGoodsDeclarationAmendmentRequestSettingListCtrlScope extends ng.IScope {


        }

        interface IGoodsDeclarationAmendmentRequestSettingListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class goodsDeclarationAmendmentRequestSettingListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'goodsDeclarationAmendmentRequestSettingService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'classificationValueService'
            ];

            goodsDeclarationAmendmentRequestSettingSearch: interfaces.master.IGoodsDeclarationAmendmentRequestSettingSearch;

            IsLoading: boolean = false;
            ShowGrid: boolean = false;
            vatId: number = 0;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay>;
            searchAccordian: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;
       
            ownerEntityId: number;

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.master.IGoodsDeclarationAmendmentRequestSettingsDisplay> = [];

            filterNames: string[] = ["EntityCode",
                "SendPendingEmailNotification",
                "PendingEmailNotificationAddress",
                "SendApprovalRejectUserAlert",                
                "IsActive"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];



            constructor(private $scope: IGoodsDeclarationAmendmentRequestSettingListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private goodsDeclarationAmendmentRequestSettingService: interfaces.master.IGoodsDeclarationAmendmentRequestSettingService,
                private $state: ng.ui.IStateService,
                private $stateParams: IGoodsDeclarationAmendmentRequestSettingListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
            ) {
                this.loadControls();
            }

            loadGoodsDeclarationAmendmentRequestSettings() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'goodsDeclarationAmendmentRequestSetting'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.goodsDeclarationAmendmentRequestSettingSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.goodsDeclarationAmendmentRequestSettingService.getList().query(params, (data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;
                            this.selectedRowsArray = [];

                            if (data[0]) {
                                this.gvwList.totalItems = data[0].NumRecs;
                            } else {
                                this.gvwList.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }





            loadControls() {
                let controlPromises = [
                    this.getCurrentEntity()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });

            }


            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                //this.goodsDeclarationAmendmentRequestSettingSearch 
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.goodsDeclarationAmendmentRequestSettingSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.goodsDeclarationAmendmentRequestSettingService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }



    

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.goodsDeclarationAmendmentRequestSettingSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.goodsDeclarationAmendmentRequestSettingService.getGoodsDeclarationAmendmentRequestSettingsExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }



            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }





            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });


                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div> 
                                <button type="button"  ng-click="grid.appScope.goodsDeclarationAmendmentRequestSettingListCtrl.editAmendment(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 60,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                {
                    name: "SendPendingEmailNotification",
                    displayName: "Send Pending Email Notification",
                    field: "SendPendingEmailNotification",
                    width: 230,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                },
                {
                    name: "PendingEmailNotificationAddress",
                    displayName: "Pending Email Notification Address",
                    field: "PendingEmailNotificationAddress",
                    width: 500,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                },{
                    name: "SendApprovalRejectUserAlert",
                    displayName: "Send Approval/Rejection User Alert",
                    field: "SendApprovalRejectUserAlert",
                    width: 240,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                },
                 {
                    name: "IsActive",
                    displayName: "Is Active",
                    field: "IsActive",
                    width: 100,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                ]
            };


            editAmendment(vatId:number)
            {
                this.vatId = vatId;
                this.addNew();
                
            }

            addNewClick(){
                this.vatId = undefined;
                this.addNew();
            }



            addNew() {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Goods Declaration Amendment Request Settings</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="goodsdecamend.add">
                            <form name="goodsdecamend">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
                                                    <req></req>
						                            <label>Owner Entity</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="ownerEntity" ng-model="addNewCtrl.goodsDeclarationAmendmentRequestSetting.Entity" form="inputForm" load-data="addNewCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Send Pending Email Notification</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"  
                                                            ng-model="addNewCtrl.goodsDeclarationAmendmentRequestSetting.SendPendingEmailNotification" 
                                                            name="SendPendingEmailNotification">
                                                    </p>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Pending Email Notification Address</label>
					                            </div>
					                            <div class="col-md-6">
                                                <p class="input-group-sm">
                                                    <textarea id="email" class="form-control" ng-model="addNewCtrl.goodsDeclarationAmendmentRequestSetting.PendingEmailNotificationAddress" rows="25"></textarea>
                                                </p>
					                            </div>
				                            </div>
                                        </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Send Approval/Reject User Alert</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"  
                                                            ng-model="addNewCtrl.goodsDeclarationAmendmentRequestSetting.SendApprovalRejectUserAlert" 
                                                            name="SendApprovalRejectUserAlert">
                                                    </p>
					                            </div>
				                            </div>
                                        </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <opt></opt>
						                            <label>Is Active</label>
					                            </div>
					                            <div class="col-md-6">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"  
                                                            ng-model="addNewCtrl.goodsDeclarationAmendmentRequestSetting.IsActive" 
                                                            ng-disabled = "addNewCtrl.goodsDeclarationAmendmentRequestSetting.Id===0"
                                                            name="IsActive">
                                                    </p>
					                            </div>
				                            </div>
			                            </div>                                                                                  
                                    
                                    </div>
                                    <div class="modal-footer">

                                    <gts-stamp-info  ng-if="addNewCtrl.goodsDeclarationAmendmentRequestSetting.Id>0" createstampfullname="addNewCtrl.goodsDeclarationAmendmentRequestSetting.CreateStampFullName" createstampdate="addNewCtrl.goodsDeclarationAmendmentRequestSetting.CreateStampDate"
                                    updatestampfullname="addNewCtrl.goodsDeclarationAmendmentRequestSetting.UpdateStampFullName" updatestampdate="addNewCtrl.goodsDeclarationAmendmentRequestSetting.UpdateStampDate">
                                    </gts-stamp-info>      

                                        <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.goodsDeclarationAmendmentRequestSetting.Entity" ng-click="addNewCtrl.add()">{{addNewCtrl.saveMessage}}</button>
                                        <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        `,
                    controller: class addNewCtrl {
                        selectedEntity: interfaces.applicationcore.IDropdownModel;

                        goodsDeclarationAmendmentRequestSetting: interfaces.master.GoodsDeclarationAmendmentRequestSetting;

                        messages: interfaces.applicationcore.IMessage[];

                        saveMessage: string = "Add New";

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private goodsDeclarationAmendmentRequestSettingService: interfaces.master.IGoodsDeclarationAmendmentRequestSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private vatId : number
                        ) {
                            this.selectedEntity = <interfaces.applicationcore.IDropdownModel>{
                                Id: $rootScope.Entity.Id,
                                Code: $rootScope.Entity.Code,
                                Description: $rootScope.Entity.Name,
                                Display: $rootScope.Entity.Code + " - " + $rootScope.Entity.Name,
                                Selected: true
                            };

                            
                            if (vatId)
                            {
                                    this.loadGoodsDeclarationAmendmentRequestSetting ();
                                    this.saveMessage = "Save";
                            }
                            else
                            {
                                this.goodsDeclarationAmendmentRequestSetting = <interfaces.master.GoodsDeclarationAmendmentRequestSetting>{
                                    Id: 0,
                                    Entity: this.selectedEntity,
                                    PendingEmailNotificationAddress : "",
                                    SendApprovalRejectUserAlert : false,
                                    SendPendingEmailNotification : false,
                                    IsActive : true                               
                                }

                                this.saveMessage = "Add New";
                            }
                        }

                        loadGoodsDeclarationAmendmentRequestSetting() {
                            return this.goodsDeclarationAmendmentRequestSettingService.getGoodsDeclarationAmendmentRequestSettingDetail().get({
                                vatId: this.vatId,
                            }, (result: interfaces.master.GoodsDeclarationAmendmentRequestSetting) => {
                                this.goodsDeclarationAmendmentRequestSetting = result;                                
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        }

                        cancel() {
                            this.$uibModalInstance.close(false);
                        }

                        add() {
                            var saveItem =  this.goodsDeclarationAmendmentRequestSetting;

                            this.goodsDeclarationAmendmentRequestSettingService.saveGoodsDeclarationAmendmentRequestSetting().save(saveItem, (result) => {
                                this.generalService.displayMessageHandler(result);
                                if (!result.HasErrorMessage) {
                                    this.$uibModalInstance.close(true);
                                }

                                this.$uibModalInstance.close(true);
                            }, (errorResult) => {
                                this.generalService.displayMessageHandler(errorResult.data);

                                if (errorResult.data.Messages) {
                                    this.messages = [];
                                }

                                angular.forEach(errorResult.data.Messages, (o) => {
                                    angular.forEach(o.MessageItems, (n) => {
                                        this.messages.push(n);
                                    });
                                });
                            });
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }


                    },
                    controllerAs: "addNewCtrl",
                    resolve: { vatId : this.vatId
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadGoodsDeclarationAmendmentRequestSettings();
                    }
                });
            }


        }

        angular.module("app").controller("goodsDeclarationAmendmentRequestSettingListCtrl", controllers.master.goodsDeclarationAmendmentRequestSettingListCtrl);
    }
}